@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap);
.esri-view-width-xsmall .esri-expand--auto .esri-expand__mask--expanded {
  background-color: unset;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
  position: relative;
}
.video-js {
  height: 100%;
  width: 100%;
}

.video-js.vjs-big-play-button:focus,
.video-js:hover .vjs-big-play-button {
  background-color: transparent;
  color: #fff;
}

.video-js .vjs-control-bar {
  background-color: #3e3e3e;
  height: 72px;
  display: flex;
}

.video-js .vjs-slider {
  background-color: #303132;
}
.video-js .vjs-control {
  display: flex !important;
  align-items: center;
}

.vjs-playback-rate .vjs-playback-rate-value {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.video-js .vjs-button > .vjs-icon-placeholder:before,
.video-js .vjs-time-control {
  line-height: 72px;
}

.video-js .vjs-play-control {
  font-size: 3em;
  position: relative;
  margin: 0 -35px;
}

.video-js .vjs-current-time,
.vjs-no-flex .vjs-current-time {
  display: flex;
  align-items: center;
}

.video-js .vjs-duration,
.vjs-no-flex .vjs-duration,
.vjs-time-divider {
  margin-left: -15px;
  display: flex;
  align-items: center;
}

.video-js .vjs-progress-control .vjs-progress-holder,
.video-js .vjs-progress-control:hover .vjs-progress-holder {
  font-size: 2em;
}
.video-js .vjs-volume-panel {
  align-items: center;
}
.video-js .vjs-play-progress,
.video-js .vjs-play-progress:before {
  background-color: #faa800;
}

.video-js .vjs-play-progress:before {
  display: none;
}

.vjs-theme-fantasy .vjs-progress-control {
  font-size: 14px;
}

.video-observation-button {
  bottom: 100px;
}

.vjs-resolution-button .vjs-icon-placeholder:before {
  content: '\f110';
  font-size: 1.8em;
  line-height: 1.67em;
}

.vjs-resolution-button .vjs-resolution-button-label {
  font-size: 1em;
  line-height: 3em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  box-sizing: inherit;
}

.vjs-resolution-button .vjs-menu .vjs-menu-content {
  width: 4em;
  left: 50%; /* Center the menu, in it's parent */
  margin-left: -2em; /* half of width, to center */
}

.vjs-resolution-button .vjs-menu li {
  text-transform: none;
  font-size: 1em;
}

.mapDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__mask--expanded {
  background-color: unset;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
  position: relative;
}

.esri-popup__icon {
  display: none;
}

.esri-popup__action-text {
  font-weight: 700;
  border: 1px solid #303132;
  padding: 5px 10px;
  border-radius: 5px;
}

.esri-icon-close,
.esri-icon-dock-right,
.esri-icon-minimize {
  display: block;
}

.mapDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__mask--expanded {
  background-color: unset;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
  position: relative;
}

.esri-popup__icon {
  display: none;
}

.esri-popup__action-text {
  font-weight: 700;
  border: 1px solid #303132;
  padding: 5px 10px;
  border-radius: 5px;
}

.esri-icon-close,
.esri-icon-dock-right,
.esri-icon-minimize {
  display: block;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.selected {
  border: 3px solid orange !important;
}

.UploadIcon {
  color: 'orange';
  font-size: 16px;
}

