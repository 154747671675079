.mapDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__mask--expanded {
  background-color: unset;
}

.esri-view-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
  position: relative;
}

.esri-popup__icon {
  display: none;
}

.esri-popup__action-text {
  font-weight: 700;
  border: 1px solid #303132;
  padding: 5px 10px;
  border-radius: 5px;
}

.esri-icon-close,
.esri-icon-dock-right,
.esri-icon-minimize {
  display: block;
}
